import ApiService from "@/core/services/api.service";
import { login, logout, loadUser, updateToken } from "@/api/auth.api";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const LOAD_USER = "loadUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_TOKEN = "setToken";
export const SET_AUTH = "setAuth";
export const SET_ERROR = "setError";
export const SET_USER = "setUser";

const state = {
  errors: null,
  user: {},
  role: [],
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentRole(state) {
    return state.role;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      login(credentials)
        .then(({ data }) => {
          context.commit(SET_TOKEN, data.token);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [LOGOUT](context) {
    logout();
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken() && JwtService.isTokenNotExpired()) {
      const user = JwtService.decodeToken();
      context.commit(SET_AUTH, user);
      ApiService.setHeader();
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [LOAD_USER](context) {
    loadUser().then(({ data }) => {
      context.commit(SET_USER, data);
    });
  },

  UPDATE_FIREBASETOKEN(context, token) {
    updateToken(token);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    state.errors = {};
    JwtService.saveToken(token);
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.role = [];
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_USER](state, user) {
    state.user = user;
    state.role = user.roles;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
