import { formatISO, formatISO9075 } from "date-fns";

export default {
  state: {
    search_start_date: formatISO(new Date(), { representation: "date" }),
    search_end_date: formatISO(new Date(), { representation: "date" }),
  },

  mutations: {
    updateSearchStartDate(state, data) {
      state.search_start_date = data;
    },
    updateSearchEndDate(state, data) {
      state.search_end_date = data;
    },
  },
};
