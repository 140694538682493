import Vue from "vue";
import ApiService from "@/core/services/api.service";
// const actionScope = `loader`;
export function setupInterceptors({ dispatch }) {
  let httpClient = ApiService.getInstance();
  let requestsPending = 0;
  const req = {
    pending: () => {
      requestsPending++;
      dispatch(`show`);
    },
    done: () => {
      requestsPending--;
      if (requestsPending <= 0) {
        dispatch(`hide`);
      }
    }
  };
  httpClient.interceptors.request.use(
    config => {
      req.pending();
      return config;
    },
    error => {
      requestsPending--;
      req.done();
      return Promise.reject(error);
    }
  );
  httpClient.interceptors.response.use(
    response => {
      req.done();
      return Promise.resolve(response);
    },
    async error => {
      req.done();

      console.log(error.response);

      if (error.response.config.responseType == "blob") {
        error.response.data.errors = (await fileToJson(error.response.data)).errors;
      }

      const vm = new Vue();
      let errorMsg = "";
      if (error.response.data) {
        for (let i = 0; i < error.response.data.errors.length; i++) {
          errorMsg += error.response.data.errors[i] + "\r\n";
        }
      }

      vm.$bvToast.toast(`${errorMsg}`, {
        title: "Server Error",
        variant: "danger",
        autoHideDelay: 8000
      });

      return Promise.reject(error);
    }
  );
}

function fileToJson(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = res => {
      const { result } = res.target; // 得到字符串
      const data = JSON.parse(result); // 解析成json对象
      resolve(data);
    }; // 成功回调
    reader.onerror = err => {
      reject(err);
    }; // 失败回调
    reader.readAsText(new Blob([file]), "utf-8"); // 按照utf-8编码解析
  });
}
