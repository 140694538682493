import { extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

// Install rules
extend("required", required);
extend("email", email);
extend("clientNotEmpty", {
  validate: value => {
    if (value > 0) {
      return true;
    }
    return "GENERAL_VALIDATION_MESSAGES_REQUIRED";
  }
});
