import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/view/layout/Layout"),
    children: [
      {
        path: "/job/listing",
        name: "ListJob",
        component: () => import("@/view/pages/logistics/jobs/ListJob.vue"),
      },
      {
        path: "/job/create",
        name: "CreateJob",
        component: () => import("@/view/pages/logistics/jobs/CreateJob.vue"),
      },
      {
        path: "/job/batch",
        name: "CreateBatchJob",
        component: () =>
          import("@/view/pages/logistics/jobs/BatchCreateJob.vue"),
      },
      {
        path: "/job/update/:id",
        name: "UpdateJob",
        component: () => import("@/view/pages/logistics/jobs/UpdateJob.vue"),
      },
      {
        path: "/job/dispatch",
        name: "DispatchJob",
        component: () => import("@/view/pages/logistics/jobs/DispatchJob.vue"),
      },
      {
        path: "/job/search",
        name: "SearchJob",
        component: () => import("@/view/pages/logistics/jobs/SearchJob.vue"),
      },
      {
        path: "/job/track",
        name: "TrackJob",
        component: () => import("@/view/pages/logistics/jobs/TrackJob.vue"),
      },
      {
        path: "/job/optimize/listing",
        name: "Optimize",
        component: () =>
          import(
            "@/view/pages/logistics/jobs/optimize/OptimizationListing.vue"
          ),
      },
      {
        path: "/job/optimize",
        name: "optimize-job-date-selection",
        component: () =>
          import("@/view/pages/logistics/jobs/optimize/TripDateSelection.vue"),
      },
      {
        path: "/job/optimize/:id",
        name: "optimize-job",
        component: () =>
          import("@/view/pages/logistics/jobs/optimize/OptimizeResult.vue"),
      },
      {
        path: "/map",
        name: "Map",
        component: () => import("@/view/pages/logistics/map/HereMap.vue"),
      },

      {
        path: "/report/job",
        name: "JobReport",
        component: () => import("@/view/pages/logistics/report/Report.vue"),
      },

      {
        path: "/client-company/listing",
        name: "ListClientCompany",
        component: () =>
          import("@/view/pages/logistics/client/ListClientCompany.vue"),
      },
      {
        path: "/client-company/create",
        name: "CreateClientCompany",
        component: () =>
          import("@/view/pages/logistics/client/CreateClientCompany.vue"),
      },
      {
        path: "/client-company/update/:id",
        name: "UpdateClientCompany",
        component: () =>
          import("@/view/pages/logistics/client/UpdateClientCompany.vue"),
      },

      {
        path: "/client-company/:id/users",
        name: "ListClientCompanyUser",
        component: () =>
          import("@/view/pages/logistics/client/ListClientCompanyUser.vue"),
      },
      {
        path: "/client-company/:id/users/create",
        name: "CreateClientCompanyUser",
        component: () =>
          import("@/view/pages/logistics/client/CreateClientUser.vue"),
      },
      {
        path: "/client-company/:company/users/update/:id",
        name: "UpdateClientCompanyUser",
        component: () =>
          import("@/view/pages/logistics/client/UpdateClientUser.vue"),
        props: true,
      },

      {
        path: "/users/listing",
        name: "ListUser",
        component: () => import("@/view/pages/logistics/user/ListUser.vue"),
      },
      {
        path: "/users/create",
        name: "CreateUser",
        component: () => import("@/view/pages/logistics/user/CreateUser.vue"),
      },
      {
        path: "/users/update/:id",
        name: "UpdateUser",
        component: () => import("@/view/pages/logistics/user/UpdateUser.vue"),
        props: true,
      },

      {
        path: "/driver/listing",
        name: "ListDriver",
        component: () => import("@/view/pages/logistics/driver/ListDriver.vue"),
      },
      {
        path: "/driver/create",
        name: "CreateDriver",
        component: () =>
          import("@/view/pages/logistics/driver/CreateDriver.vue"),
      },
      {
        path: "/driver/update/:id",
        name: "UpdateDriver",
        component: () =>
          import("@/view/pages/logistics/driver/UpdateDriver.vue"),
        props: true,
      },

      {
        path: "/admin/address-book",
        name: "ListAddressbook",
        component: () =>
          import("@/view/pages/logistics/addressbook/ListAddressbook.vue"),
      },
      {
        path: "/admin/address-book/create",
        name: "CreateAddressbook",
        component: () =>
          import("@/view/pages/logistics/addressbook/CreateAddressbook.vue"),
      },
      {
        path: "/admin/address-book/update/:id",
        name: "UpdateAddressbook",
        component: () =>
          import("@/view/pages/logistics/addressbook/UpdateAddressbook.vue"),
        props: true,
      },
      {
        path: "/warehouse/dashboard",
        name: "WarehouseDashboard",
        component: () =>
          import("@/view/pages/logistics/warehouse/Dashboard.vue"),
      },
      {
        path: "/warehouse/listing",
        name: "ListWarehouseProduct",
        component: () =>
          import("@/view/pages/logistics/warehouse/ListProduct.vue"),
      },
      {
        path: "/warehouse/create",
        name: "CreateWarehouseProduct",
        component: () =>
          import("@/view/pages/logistics/warehouse/CreateProduct.vue"),
      },
      {
        path: "/warehouse/update/:id",
        name: "UpdateProduct",
        component: () =>
          import("@/view/pages/logistics/warehouse/UpdateProduct.vue"),
        props: true,
      },
      {
        path: "/warehouse/view/:id",
        name: "ViewProductMovement",
        component: () =>
          import("@/view/pages/logistics/warehouse/ViewProductMovement.vue"),
        props: true,
      },
      {
        path: "/warehouse/inbound",
        name: "InboundWarehouseProduct",
        component: () => import("@/view/pages/logistics/warehouse/Inbound.vue"),
      },
      {
        path: "/warehouse/outbound",
        name: "OutboundWarehouseProduct",
        component: () =>
          import("@/view/pages/logistics/warehouse/Outbound.vue"),
      },
      // ======================= END OF LOGISTICS =============================================
      // ======================= START OF CLIENT =================================================
      {
        path: "/client/job/listing",
        name: "ListClientJob",
        component: () => import("@/view/pages/client/jobs/ListJob.vue"),
      },
      {
        path: "/client/job/create",
        name: "CreateClientJob",
        component: () => import("@/view/pages/client/jobs/CreateJob.vue"),
      },
      {
        path: "/client/job/batch",
        name: "CreateClientBatchJob",
        component: () => import("@/view/pages/client/jobs/BatchCreateJob.vue"),
      },
      {
        path: "/client/job/update/:id",
        name: "UpdateClientJob",
        component: () => import("@/view/pages/client/jobs/UpdateJob.vue"),
      },
      {
        path: "/client/job/search",
        name: "ClientSearchJob",
        component: () => import("@/view/pages/client/jobs/SearchJob.vue"),
      },
      {
        path: "/client/job/track",
        name: "ClientTracking",
        component: () => import("@/view/pages/client/jobs/TrackJob.vue"),
      },
      {
        path: "/client/address-book",
        name: "ListClientAddressbook",
        component: () =>
          import("@/view/pages/client/addressbook/ListAddressbook.vue"),
      },
      {
        path: "/client/address-book/create",
        name: "CreateClientAddressbook",
        component: () =>
          import("@/view/pages/client/addressbook/CreateAddressbook.vue"),
      },
      {
        path: "/client/address-book/update/:id",
        name: "UpdateClientAddressbook",
        component: () =>
          import("@/view/pages/client/addressbook/UpdateAddressbook.vue"),
        props: true,
      },
      //========================================================
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/view/pages/Dashboard.vue"),
      },

      {
        path: "/change-password",
        name: "ChangePassword",
        component: () => import("@/view/pages/auth/ChangePassword.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/view/pages/error/Error.vue"),
    children: [
      {
        path: "error-1",
        name: "error-1",
        component: () => import("@/view/pages/error/Error-1.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/view/pages/auth/Auth"),
    children: [
      {
        name: "login",
        path: "/login",
        component: () => import("@/view/pages/auth/Login"),
      },
      {
        name: "register",
        path: "/register",
        component: () => import("@/view/pages/auth/Register"),
      },
    ],
  },
  {
    path: "*",
    redirect: "/404",
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/view/pages/error/Error-1.vue"),
  },
];

const router = new Router({
  mode: "history",
  routes,
});

export default router;
