import { getMultipleWaybill, getMultipleECommerceLabel } from "@/api/job.api";

export default {
  state: {
    print_list: [],
    print_count: 0
  },
  actions: {
    addToPrintList(context, data) {
      context.commit("addToPrintList", data);
    },
    clearPrintList(context) {
      context.commit("clearPrintList");
    },
    downloadMultipleWaybill({ getters }) {
      const payload = {
        id: getters.printList
      };

      getMultipleWaybill(payload).then(response => {
        const newBlob = new Blob([response.data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `multiple_waybill.pdf`;
        link.click();
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    },
    downloadMultipleLabel({ getters }) {
      const payload = {
        id: getters.printList
      };

      getMultipleECommerceLabel(payload).then(response => {
        const newBlob = new Blob([response.data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `multiple_label.pdf`;
        link.click();
        setTimeout(function() {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      });
    }
  },
  mutations: {
    addToPrintList(state, data) {
      state.print_list.unshift(data);
    },
    clearPrintList(state) {
      state.print_list = [];
      state.print_count = 0;
    }
  },
  getters: {
    printList: state => {
      return [...new Set(state.print_list)];
    },
    printCount: state => [...new Set(state.print_list)].length
  }
};
