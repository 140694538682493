import ApiService from "@/core/services/api.service";

const END_POINT = "/jobs";

const TRIP_END_POINT = "/trips";

const listClient = () => ApiService.query(`${END_POINT}/client`);

const listJobWithinDate = params => ApiService.query(`${END_POINT}`, params);

const createOrder = param => ApiService.post(`${END_POINT}`, param);

const getJobFullDetails = id => ApiService.get(`${END_POINT}`, id);

const getWaybill = id => ApiService.download(`${END_POINT}/waybill/${id}`);

const getECommerceLabel = id => ApiService.download(`${END_POINT}/ecommerce/${id}`);

const getUpdateJobDetail = id => ApiService.get(`${END_POINT}/${id}/update`);

const updateJob = (id, payload) => ApiService.post(`${END_POINT}/${id}/update`, payload);

const searchJob = param => ApiService.query(`${END_POINT}/search`, param);

const getMultipleWaybill = param => ApiService.download(`${END_POINT}/waybill/multiple`, param);

const getMultipleECommerceLabel = param => ApiService.download(`${END_POINT}/ecommerce/multiple`, param);

// ============================================================================

const listJobsForDispatch = () => ApiService.get(`${END_POINT}/dispatchs`);

const dispatchTripToDriver = (trip, driver) => ApiService.put(`${TRIP_END_POINT}/${trip}/driver/${driver}`, null);

const duplicateTrip = id => ApiService.post(`${TRIP_END_POINT}/${id}/duplicate`);

const getProofOfDocument = id => ApiService.download(`${TRIP_END_POINT}/proof/${id}`);

// ===============================================================================

const listTracking = id => ApiService.get(`${END_POINT}/tracking/${id}`);

export {
  listClient,
  listJobWithinDate,
  createOrder,
  listJobsForDispatch,
  dispatchTripToDriver,
  duplicateTrip,
  getJobFullDetails,
  getWaybill,
  getECommerceLabel,
  getUpdateJobDetail,
  updateJob,
  searchJob,
  getProofOfDocument,
  getMultipleWaybill,
  getMultipleECommerceLabel,
  listTracking
};
