import ApiService from "@/core/services/api.service";

const END_POINT = "/auth";

const login = param => ApiService.post(`${END_POINT}/token`, param);

const logout = () => ApiService.post(`${END_POINT}/logout`);

const loadUser = () => ApiService.get(`/user/current-user`);

const updateToken = token => ApiService.post(`/user/firebasetoken/${token}`);

const changePassword = payload => ApiService.post(`/user/change-password`, payload);

export { login, logout, loadUser, updateToken, changePassword };
