// import Vue from "vue";
import axios from "axios";
import Qs from "qs";
// import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const http = axios.create({
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: "repeat" })
});
const ApiService = {
  getInstance() {
    return http;
  },

  init() {
    // Vue.use(VueAxios, axios);
    http.defaults.baseURL = process.env.VUE_APP_API_URL;

    // http.interceptors.response.use(
    //   r => r,
    //   error => {
    //     const vm = new Vue();
    //     vm.$bvToast.toast(`${error.response.data.message}`, {
    //       title: "Server Error",
    //       variant: "danger"
    //     });
    //     return Promise.reject(error);
    //   }
    // );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    http.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
  },

  query(resource, params) {
    return http
      .get(resource, {
        params
      })
      .catch(error => {
        // console.log(error);
        throw new Error(`[KT] ApiService ${error}`);
      });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return http.get(`${resource}/${slug}`).catch(error => {
      console.log(error);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return http.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return http.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return http.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return http.delete(resource).catch(error => {
      // console.log(error);
      throw new Error(`[RWV] ApiService ${error}`);
    });
  },

  download(resource, params) {
    return http
      .get(resource, {
        params: params,
        responseType: "blob"
      })
      .catch(error => {
        // console.log(error);
        throw new Error(`[RWV] ApiService ${error}`);
      });
  }
};

export default ApiService;
