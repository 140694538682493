import jwt_decode from "jwt-decode";

const ID_TOKEN_KEY = "id_token";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const decodeToken = () => {
  const token = getToken();

  return jwt_decode(token);
};

export const isTokenNotExpired = () => {
  const token = getToken();
  if (token) {
    const body = jwt_decode(token);

    if (Date.now() >= body.exp * 1000) {
      return false;
    }

    return true;
  } else {
    return false;
  }
};

export default {
  getToken,
  saveToken,
  destroyToken,
  decodeToken,
  isTokenNotExpired
};
