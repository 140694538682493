export const ADD_BODY_CLASSNAME = "addBodyClassName";
export const REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export default {
  state: {
    loading: false
  },
  actions: {
    show({ commit, dispatch }) {
      commit("show");
      dispatch(ADD_BODY_CLASSNAME, "page-loading");
    },
    hide({ commit, dispatch }) {
      commit("hide");
      dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
  mutations: {
    show(state) {
      state.loading = true;
    },
    hide(state) {
      state.loading = false;
    }
  }
};
