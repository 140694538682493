<template>
  <button
    :type="type"
    v-bind:class="variant"
    class="btn font-weight-bolder text-uppercase px-6"
    :disabled="loading"
    @click="$emit('button-click')"
  >
    <b-spinner small v-show="loading"></b-spinner>
    {{ text }}
  </button>
</template>

<script>
import { ref } from "@vue/composition-api";
export default {
  props: {
    type: {
      type: String,
      default: "submit"
    },
    text: {
      type: String,
      default: "Submit"
    },

    variant: {
      type: String,
      default: "btn-primary"
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  setup() {
    const showSpinner = ref(false);

    return {
      showSpinner
    };
  }
};
</script>
