import { differenceInMinutes } from "date-fns";

export default {
  state: {
    alert: [],
    notification_count: 0
  },
  actions: {
    onMessage(context, data) {
      context.commit("addToNotification", data);
    },
    clearNotifications(context) {
      context.commit("clearNotification");
    },
    updateTime(context) {
      context.commit("updateTime");
    }
  },
  mutations: {
    addToNotification(state, data) {
      state.alert.unshift({ title: data.title, body: data.body, receive: new Date(), ago: "" });
      state.notification_count++;
    },
    clearNotification(state) {
      state.alert = [];
      state.notification_count = 0;
    },
    updateTime(state) {
      state.alert.forEach((element, i) => {
        Object.assign(state.alert[i], { ago: differenceInMinutes(new Date(), element.receive) + " min ago" });
      });
    }
  },
  getters: {
    notifications: state => {
      return state.alert;
    },
    notificationCount: state => state.notification_count
  }
};
